import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import {
  CREATE_PRODUCT_BRAND,
  CREATE_PRODUCT_GROUP_MUTATION,
  UPDATED_PRODUCT_BRAND,
  UPDATE_PRODUCT_GROUP_MUTATION,
  UPDATE_PRODUCT_MUTATION,
  UPDATE_PRODUCT_STATUS,
  UPLOAD_FILE_PRODUCT_PRICE_LOGS_MUTATION,
} from "graphql/product/mutation";

import {
  BRAND,
  CHECK_DUPLICATED_PRODUCT,
  GET_ALL_CATEGORIES_QUERY,
  GET_ATTRIBUTE_OF_CATEGORY,
  GET_BY_VARIANT_ATTRIBUTE,
  GET_DETAIL_PRODUCT_BRAND,
  GET_IMPORT_FILE_PRODUCT,
  GET_LIST_PRICE_BID_WAIT_RESPONSE,
  GET_MANUFACTURES_AND_WAREHOUSE,
  GET_PRODUCTS_QUERY,
  GET_PRODUCT_BRAND,
  GET_PRODUCT_GROUP_INIT_FORM_QUERY,
  GET_PRODUCT_INIT_FORM_QUERY,
  GET_PRODUCT_PRICELOG,
  GET_PRODUCT_QUERY,
  GET_PRODUCT_WITH_IDS,
  GET_RECOMMEND_PRODUCT_NAME,
  GET_SUGGESTED_PROD_GROUP,
} from "graphql/product/query";

import { USER_PERMISSIONS } from "config/constants";
import { GET_UOM_QUERY } from "graphql/uom/query";
import { useUploadDocument } from "hooks/upload-document";
import { useGetUserPermissions } from "hooks/user/user";
import { hasPermission } from "utils/helperFuncs";
import { converterCategoriesForForm, converterRecommendProductName, converterUom } from "./converter";

const LEVEL_FORM_SELECT = 3;

export const useGetProducts = (params, fetchPolicy = "no-cache") => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_PRODUCTS_QUERY, {
    variables: { withCart: false, ...params },
    fetchPolicy: fetchPolicy,
    skip: !params,
  });

  const products = data?.product?.pagination?.products || [];
  const currentTotalProducts = products?.length;
  const paginationData = data?.product?.pagination?.paginationData;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalProducts || 0, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.product?.pagination?.products) return pv;
        const newData = {
          product: {
            pagination: {
              paginationData: { ...fetchMoreResult?.product?.pagination?.paginationData },
              products: [...pv?.product?.pagination?.products, ...fetchMoreResult?.product?.pagination?.products],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalProducts === paginationData?.total);

  return {
    loading,
    paginationData,
    data: products,
    total: paginationData?.total,
    refetch,
    fetchMore,
    hasMoreData,
    onLoadMoreData,
  };
};

export function useGetProduct(params) {
  const { loading, data } = useQuery(GET_PRODUCT_QUERY, {
    variables: params,
    skip: !params?.id,
  });
  return {
    data: data?.product?.get,
    loading,
  };
}

export const useUpdateProduct = () => {
  const [updateProduct, { loading, error }] = useMutation(UPDATE_PRODUCT_MUTATION);

  const handleUpdateProduct = async ({
    productID,
    vat,
    vatPercent,
    prices,
    vatValue,
    note,
    productDiscounts,
    sellingStatus,
    vatType,
    ...params
  }) => {
    return await updateProduct({
      variables: {
        productID,
        vat,
        vatPercent,
        vatValue,
        prices,
        note,
        productDiscounts,
        sellingStatus,
        vatType,
        ...params,
      },
      refetchQueries: [GET_PRODUCTS_QUERY],
    });
  };
  return {
    handleUpdateProduct,
    loading,
    error,
  };
};

export function useGetUpdatedProduct(params) {
  const { loading, data, refetch } = useQuery(GET_PRODUCT_QUERY, {
    variables: params,
    skip: !params?.id,
  });
  return {
    data: data?.product?.get,
    loading,
    refetch,
  };
}

export const useUpdateProductStatus = () => {
  const [updateProductStatus, { loading, error }] = useMutation(UPDATE_PRODUCT_STATUS);

  const handleUpdateProductStatus = async (params) => {
    return await updateProductStatus({
      variables: params,
      skip: !params?.ids,
      refetchQueries: [GET_PRODUCTS_QUERY],
    });
  };
  return {
    handleUpdateProductStatus,
    loading,
    error,
  };
};

export const useProductPermissions = () => {
  const { data: permissions = [], loading } = useGetUserPermissions();
  return {
    loadingProductPermissions: loading,
    canViewProduct: hasPermission(permissions, [USER_PERMISSIONS.PRODUCT_VIEW]),
    canUpdate: hasPermission(permissions, [USER_PERMISSIONS.PRODUCT_UPDATE, USER_PERMISSIONS.PRODUCT_UPDATE_PRICE]),
    canUpdateInfo: hasPermission(permissions, [USER_PERMISSIONS.PRODUCT_UPDATE]),
    canUpdatePrice: hasPermission(permissions, [USER_PERMISSIONS.PRODUCT_UPDATE_PRICE]),
  };
};

export function useLazyGetVariantByAttValue() {
  const [getVariant, { loading, data }] = useLazyQuery(GET_BY_VARIANT_ATTRIBUTE);
  return {
    data: data?.product?.getByVariantAttribute,
    loading,
    getVariant,
  };
}

export const useGetAllCategories = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_ALL_CATEGORIES_QUERY, {
    variables: { req: { isActive: true, levels: [1] } },
  });

  return {
    loading,
    data: converterCategoriesForForm(data?.category?.list, LEVEL_FORM_SELECT),
    refetch,
    fetchMore,
  };
};

export const useGetAttributeOfCategory = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_ATTRIBUTE_OF_CATEGORY, {
    variables: { req: { isActive: true, categoryIDs: [params] } },
    skip: params === undefined || params === null,
  });

  return { loading, data: data?.attribute?.list, refetch, fetchMore };
};

export const useGetUom = () => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_UOM_QUERY);

  return { loading, data: converterUom(data?.uom?.list), refetch, fetchMore };
};

export const useCreateProductGroup = () => {
  const [createProductGroup, { loading, error }] = useMutation(CREATE_PRODUCT_GROUP_MUTATION);

  const handleCreateProductGroup = async (params) => {
    return await createProductGroup({
      variables: { req: { ...params } },
    });
  };
  return {
    handleCreateProductGroup,
    loading,
    error,
  };
};

export const useGetRecommendProductName = (params) => {
  const { loading, data, fetchMore } = useQuery(GET_RECOMMEND_PRODUCT_NAME, {
    variables: params,
    skip: !params?.filters?.query,
    fetchPolicy: "cache-first",
  });

  const products = data?.product?.pagination?.products || [];
  const paginationData = data?.product?.pagination?.paginationData;
  const currentTotalProducts = products.length;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalProducts, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.product?.pagination?.products) return pv;
        const newData = {
          product: {
            pagination: {
              paginationData: { ...fetchMoreResult?.product?.pagination?.paginationData },
              products: [...pv?.product?.pagination?.products, ...fetchMoreResult?.product?.pagination?.products],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalProducts === paginationData?.total);

  return {
    loading,
    paginationData,
    data: converterRecommendProductName(products),
    onLoadMoreData,
    hasMoreData,
  };
};

export const useGetManufacturersAndWarehouse = () => {
  const { loading, data } = useQuery(GET_MANUFACTURES_AND_WAREHOUSE);

  return {
    loading,
    manufacturer: data?.manufacturer?.list,
    warehouses: data?.warehouse?.list?.warehouses,
  };
};

export function useGetProductInitForm(params) {
  const { loading, data, refetch } = useQuery(GET_PRODUCT_INIT_FORM_QUERY, {
    variables: params,
    skip: !params?.id,
  });
  return {
    data: data?.product?.get,
    loading,
    refetch,
  };
}

export const useUpdateProductGroup = () => {
  const [updateProductGroup, { loading, error }] = useMutation(UPDATE_PRODUCT_GROUP_MUTATION);

  const handleUpdateProductGroup = async (id, req) => {
    return await updateProductGroup({
      variables: { id: id, req: req },
      skip: !id,
    });
  };
  return {
    handleUpdateProductGroup,
    loading,
    error,
  };
};

export function useGetProductGroupInitForm(id) {
  const { loading, data, refetch } = useQuery(GET_PRODUCT_GROUP_INIT_FORM_QUERY, {
    variables: { id },
    skip: !id,
  });
  return {
    data: data?.productGroup?.get,
    loading,
    refetch,
  };
}

export const useGetSuggestedProdGroup = (params) => {
  const { loading, data, fetchMore } = useQuery(GET_SUGGESTED_PROD_GROUP, {
    variables: params,
    skip: !params?.filters?.query,
    fetchPolicy: "cache-first",
  });

  const productGroup = data?.productGroup?.pagination?.products || [];
  const paginationData = data?.productGroup?.pagination?.paginationData;
  const currentTotalProductGroup = productGroup.length;

  const onLoadMoreData = async () => {
    await fetchMore({
      variables: { pagination: { offset: currentTotalProductGroup, limit: 10 } },
      updateQuery: (pv, { fetchMoreResult }) => {
        if (!fetchMoreResult?.productGroup?.pagination?.products) return pv;
        const newData = {
          productGroup: {
            pagination: {
              paginationData: { ...fetchMoreResult?.productGroup?.pagination?.paginationData },
              products: [
                ...pv?.productGroup?.pagination?.products,
                ...fetchMoreResult?.productGroup?.pagination?.products,
              ],
            },
          },
        };
        return newData;
      },
    });
  };

  const hasMoreData = !(currentTotalProductGroup === paginationData?.total);

  return {
    loading,
    data: productGroup,
    onLoadMoreData,
    paginationData,
    hasMoreData,
  };
};

export function useGetProductPriceLog(params) {
  const { data, loading, refetch } = useQuery(GET_PRODUCT_PRICELOG, {
    variables: params,
  });
  return {
    data: data?.product?.priceLogs?.priceLogs,
    paginationData: data?.product?.priceLogs?.paginationData,
    loading,
    refetch,
  };
}

export const useUploadFileProductPriceLogs = () => {
  const [importProductPrices, { loading }] = useMutation(UPLOAD_FILE_PRODUCT_PRICE_LOGS_MUTATION);

  const handleImportProductPrices = async (file) => {
    return await importProductPrices({
      variables: { file },
      refetchQueries: [GET_PRODUCTS_QUERY],
    });
  };

  return {
    handleImportProductPrices,
    importProductPrices,
    loading,
  };
};
export const useGetImportFileProduct = () => {
  const [getImportFile, { loading, refetch }] = useLazyQuery(GET_IMPORT_FILE_PRODUCT);
  const handleExportFileProduct = async (params) => {
    return await getImportFile({
      variables: params,
    });
  };
  return {
    handleExportFileProduct,
    getImportFile,
    loading,
    refetch,
  };
};
export const useGetListPriceBidWaitResponse = (params) => {
  const { data, loading, refetch } = useQuery(GET_LIST_PRICE_BID_WAIT_RESPONSE, {
    variables: params,
  });
  return {
    data: data?.priceBidSession?.pagination?.priceBidSessions,
    paginationData: data?.priceBidSession?.pagination?.paginationData,
    loading,
    refetch,
  };
};

export const useGetListProductBrand = (params) => {
  const { data, loading, refetch } = useQuery(GET_PRODUCT_BRAND, {
    variables: params,
  });
  return {
    data: data?.brand?.pagination?.brands,
    paginationData: data?.brand?.pagination?.paginationData,
    loading,
    refetch,
  };
};

export const useCreateProductBrand = () => {
  const [create, { loading, error }] = useMutation(CREATE_PRODUCT_BRAND);
  const { handleUpload } = useUploadDocument();

  const handleCreateProductBrand = async (params) => {
    const newImagesCatalogs = params?.request?.catalogs?.filter((item) => !item?.url);
    const newImageURLsCatalogs = await handleUpload({
      files: newImagesCatalogs?.map((item) => item?.originFileObj),
    });
    const newImagesLogo = params?.request?.logo?.filter((item) => !item?.url);
    const newImageURLsLogo = await handleUpload({
      files: newImagesLogo?.map((item) => item?.originFileObj),
    });
    const urlImageLogo = newImageURLsLogo[0]?.url;
    return await create({
      variables: {
        ...params,
        request: {
          ...params?.request,
          catalogs: newImageURLsCatalogs?.map(({ url }) => url),
          logo: urlImageLogo,
        },
      },
    });
  };

  return {
    handleCreateProductBrand,
    loading,
    error,
  };
};

export const useGetDetailProductBrand = (id) => {
  const { data, loading, refetch } = useQuery(GET_DETAIL_PRODUCT_BRAND, {
    variables: id,
  });

  return {
    data: data?.brand?.get,
    loading,
    refetch,
  };
};

export const useUpdateProductBrand = () => {
  const [update, { loading, error }] = useMutation(UPDATED_PRODUCT_BRAND);
  const { handleUpload } = useUploadDocument();

  const handleUpdateProductBrand = async (params) => {
    const oldImagesCatalogs = params?.request?.catalogs?.filter((item) => item?.url);
    const newImagesCatalogs = params?.request?.catalogs?.filter((item) => !item?.url);
    const newImageURLsCatalogs = await handleUpload({
      files: newImagesCatalogs?.map((item) => item?.originFileObj) || [],
    });
    const oldImagesLogo = params?.request?.logo?.filter((item) => item?.url);
    const newImagesLogo = params?.request?.logo?.filter((item) => !item?.url);
    const imagesLogo = newImagesLogo?.map((item) => item?.originFileObj || "");
    const result = imagesLogo.filter((item) => item !== "");
    let newImageURLsLogo;
    if (oldImagesLogo?.length > 0) {
      newImageURLsLogo = oldImagesLogo[0].url;
    } else {
      if (result.length > 0) {
        newImageURLsLogo = await handleUpload({
          files: imagesLogo,
        });
      } else {
        newImageURLsLogo = "";
      }
    }
    const urlImageCatalog = oldImagesCatalogs
      ?.map((item) => item.url || "")
      .concat(newImageURLsCatalogs?.map(({ url }) => url || ""));
    const urlImageLogo = newImageURLsLogo[0]?.url || oldImagesLogo[0]?.url;

    return await update({
      variables: {
        ...params,
        request: {
          ...params?.request,
          catalogs: urlImageCatalog.length > 0 ? urlImageCatalog : [""],
          logo: urlImageLogo || "",
        },
      },
    });
  };

  return {
    handleUpdateProductBrand,
    loading,
    error,
  };
};

export const useGetDetailBrand = () => {
  const [data, { loading, refetch }] = useLazyQuery(GET_DETAIL_PRODUCT_BRAND);
  const handlegetDetailBrand = async (id) => {
    return await data({
      variables: id,
    });
  };
  return {
    handlegetDetailBrand,
    loading,
    refetch,
  };
};

export const useUpdateStatusBrand = () => {
  const [update, { loading, error }] = useMutation(UPDATED_PRODUCT_BRAND);
  const handleUpdateStatusBrand = async (request) => {
    return await update({
      variables: {
        request,
      },
      refetchQueries: ["GetProductBrand"],
    });
  };
  return {
    handleUpdateStatusBrand,
    loading,
    error,
  };
};

export const useGetBrand = () => {
  const { loading, data, refetch } = useQuery(BRAND);
  const convertData = data?.brand?.list
    ?.filter((item) => item.isActive === true)
    ?.map(({ id, name, isActive }) => {
      return {
        label: name,
        value: id,
        isActive: isActive,
      };
    });
  return {
    loadingBrand: loading,
    dataBrand: convertData,
    refetch,
  };
};

export const useGetProductsPriceDrop = () => {
  const [getProductsPriceProp, { loading, data }] = useLazyQuery(GET_PRODUCTS_QUERY);

  const handleGetProductsPriceProp = async (params) => {
    return await getProductsPriceProp({
      variables: { withCart: false, ...params },
      fetchPolicy: "no-cache",
    });
  };

  return {
    loading,
    data: data?.product?.pagination?.products || [],
    paginationData: data?.product?.pagination?.paginationData,
    handleGetProductsPriceProp,
  };
};

export const useGetProductWithIds = (params) => {
  const { loading, data, refetch, fetchMore } = useQuery(GET_PRODUCT_WITH_IDS, {
    variables: { filters: { ...params } },
    skip: params?.ids === undefined,
  });

  const options = data?.product?.pagination?.products?.map((item) => ({
    value: item?.id,
    label: item?.code,
  }));
  return {
    loading,
    data: options || [],
    refetch,
    fetchMore,
  };
};

export const useCheckDuplicatedProduct = () => {
  const [checkDuplicatedProduct, { loading, refetch }] = useLazyQuery(CHECK_DUPLICATED_PRODUCT);
  const handleCheckDuplicatedProduct = async (request) => {
    return await checkDuplicatedProduct({
      variables: request,
    });
  };
  return {
    handleCheckDuplicatedProduct,
    loading,
    refetch,
  };
};
